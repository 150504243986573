import { palette } from './theme/colorPalette';
import { createMuiTheme } from '@material-ui/core/styles';
import { typography } from './theme/typography';
import { overrides } from './theme/overrides';

const theme = createMuiTheme({
  typography,
  palette,
  overrides,
});

export {
  theme
};
