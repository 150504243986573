import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tabs , Tab, Typography, Button }  from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { Offline } from 'react-detect-offline';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Logout from '../../helper/logout';

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      {...props}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginLeft: '20px',
    display: 'inline-grid',
    position: 'absolute',
  },
  installButton: {
    position: 'absolute',
    display: 'inline-grid',
    marginLeft: '1350px',
    marginTop: '-14',
  },
  logoutButton: {
    marginLeft: '1500px',
    marginTop: '-11px',
    position: 'absolute',
    display: 'inline-grid',
  },
  icon: {
    display: 'inline-grid',
    position: 'absolute',
    marginLeft: '14px',
  },
  buttonLabel:{
    color: '#ffffff',
    display: 'inline-flex',
  }
}));

export default function NavTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

    return (
    <div className={classes.root}>
        <AppBar position="static">
            <Offline>
                <Typography variant="h6" className={classes.title} id="appBar">
                    Quickmail Warehousing Offline
                </Typography>
            </Offline>
            <Typography variant="h6" className={classes.title} id="appBar">
                Quickmail Warehousing
            </Typography>
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
            >
                <LinkTab label="Home" href="/" />
            </Tabs>
            <div className={classes.installButton}>
            <Button
              className="addButton"
              style={{ top: "-14px"}}
            >
              <Typography variant="body1" className={classes.buttonLabel} id="install">
                Install
              </Typography>
              <CloudDownload className={classes.icon}/> 
            </Button>
            </div>
          <div className={classes.logoutButton}>
            <Logout />
          </div>
        </AppBar>
    </div>
    );
}