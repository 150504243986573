import React from 'react';
import { createStyles, makeStyles, Theme, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import Amplify from 'aws-amplify';
import  awsconfig from '../../aws-exports';
import { ExpandMore } from '@material-ui/icons';
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      padding: '30px',
      color: '#ffffff'
    },
    panel: {
      display: 'block',
      background: '#ffffff'
    },
    infoPanel: {
      background: '#5ba55e'
    },
    expandIcon: {
      height: 'inherit',
      color: '#ffffff'
    }
  }));

interface Props {
    ReceivalId: string;
  }
  
  const ExistingImages = ( props: Props) => {
    const classes = useStyles();
  return (
    <ExpansionPanel className={classes.infoPanel} key={`expansion-panel-${props.ReceivalId}`} id={`expansion-panel-${props.ReceivalId}`}>
      <ExpansionPanelSummary id={`expansionSummary-${props.ReceivalId}`}>
        <ExpandMore className={classes.expandIcon} />
      <Typography component="h3" variant="h3" className={classes.heading} >
        Image Uploaded
      </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panel}>
      <img src={`${process.env.REACT_APP_IMAGE_URL}/public/${process.env.REACT_APP_PREFIX}${props.ReceivalId}.png`} alt="Preview" style={{ width: '350px' }}/>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ExistingImages;