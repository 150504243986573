import * as React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export const NotificationStatus = () => {

    return (
        <Snackbar open={true} autoHideDuration={6000} anchorOrigin={ {vertical: 'bottom', horizontal: 'left' } }>
            <Alert  severity="error">
            You are offline!
            </Alert>
        </Snackbar>
    )
}

export const UploadStatus = () => { 
    return (
    <Snackbar open={true} autoHideDuration={6000} anchorOrigin={ {vertical: 'top', horizontal: 'left' } }>
        <Alert  severity="error">
        Image will be saved locally, it will be uploaded once internet connection is detected
        </Alert>
    </Snackbar>
    )
}