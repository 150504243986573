import React from 'react';
import { makeStyles, Typography, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    alignItems: 'flex-start',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      display: 'inline-grid'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

const DrfTableRow = ({label, value}) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <Typography component="p" variant="body1" className={classes.column}>
        {label}
      </Typography>
      <Typography component="p" variant="body1" className={classes.column}>
        {value}
      </Typography>
    </div>
  );
}

export default DrfTableRow;
