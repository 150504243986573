import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure(config);

export const updateReceivables = `mutation updateReceivables($receivableInput: ReceivableInput){
    updateReceivables(receivableInput: $receivableInput) {
      ReceivableID
      ReceivedDate
      ActualQty
      UnitCount
      UnitID
      CountedDate
      Status
      CountingTime
    }
  }`;

export const updatedReceivables = async () => {
    const subscription = await API.graphql(graphqlOperation(updateReceivables)).subscribe();
    subscription.unsubscribe();
}

const updateDRF = async (
    id: string, receivedDate: string, qty: string, unitID: string,
    unitCount: string, countedDate: string, status: number,
    countingTime: number,
) => {
    console.log(`countingTime: ${countingTime}`);
    await API.graphql(graphqlOperation(updateReceivables, {
        receivableInput: {
            ReceivableID: id,
            ReceivedDate: receivedDate,
            ActualQty: qty,
            UnitID: unitID,
            UnitCount: unitCount,
            CountedDate: countedDate,
            Status: status,
            CountingTime: countingTime
        }
    }))
}

export default updateDRF;
