import clsx from 'clsx';
import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import 
{CssBaseline,
  Divider,
  AppBar,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  MenuItem,
  MenuList,
  Typography,
  createStyles,
  Button
}
from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import Banner from '../Notification/banner';
import { UserLogout } from '../../helper/logout';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  root: {
    display: 'flex',
    marginTop: '127px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
    margin: '0px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerItems: {
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: '#bec5dad9',
    },
  },
  drfButton: {
    background: 'transparent !important'
  }
}));


function MobileMenuBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  const drawer = (
    <div>
      <MenuList>
      <MenuItem className={classes.drawerItems} id="drawer"> 
      <Button
        className={classes.drfButton}
        href={`/`}
      >
      <Typography variant="body1" noWrap>
          Home
      </Typography>
      </Button>
      </MenuItem>
      <MenuItem className={classes.drawerItems} id="drawer"> 
          <Button
            className={classes.drfButton}
            onClick={UserLogout}
          >
            <Typography variant="body1" noWrap>
              Logout
            </Typography>
          </Button>
      </MenuItem>
      </MenuList>
    </div>
  );

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton> 
          <Typography variant="h6" className={classes.title} id="appBar">
          Quickmail Warehousing
          </Typography>
        </Toolbar>
        <Banner/>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} style={{ marginLeft: '7em' }}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {drawer}
      </Drawer>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
    </main>
    </div>
    </ClickAwayListener>
  );
}

export default MobileMenuBar;
