import React from "react";
import { Grid } from "@material-ui/core";
// import { ReceivableData } from "./tableData";
// import moment from "moment";
// import DrfTableRow from "./drfTableRow";

// const useStyles = makeStyles(theme => ({
//     container: {
//         left: '8px',
//         paddingTop: ' 1em',
//         display: 'grid',
//     },
//     expandedContainer: {
//         display: 'flex',
//         flexFlow: 'column',
//     },
//     itemHeader: {
//         textAlign: 'left',
//         [theme.breakpoints.down('md')]: {
//             fontSize: '16px',
//         },
//         [theme.breakpoints.down('sm')]: {
//             fontSize: '14px',
//         },
//         [theme.breakpoints.down('xs')]: {
//             fontSize: '12px',
//         },
//     },
//     table: {
//         marginTop: '50px'
//     },
//     appBar: {
//         display: 'contents',
//         position: 'static',
//         marginTop: '50px'
//     },
//     tabPanel: {
//         width: '100%',
//     },
//     tab: {
//         color: '#4088c6 !important',
//         backgroundColor: '#fafafa',
//         marginTop: '50px',
//         width: '100%',
//         fontWeight: 700
//     }
// }));

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Grid
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`tsb-${index}`}
            {...other}
        >
            {value === index && <Grid>{children}</Grid>}
        </Grid>
    );
}

export function tabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tab-${index}`,
    };
}

// export const receivableItems = (receivables: ReceivableData[] , status: number) => {
//     const classes = useStyles();
//     const items = receivables && receivables.map(r => (
//         status === r.status ?
//             <ExpansionPanel key={`expansion-panel-${r.receivalId}`} id={`expansion-panel-${r.receivalId}`}>
//                 <ExpansionPanelSummary id={`expansionSummary-${r.receivalId}`}>
//                     <Grid container>
//                         <Grid id={`${r.receivalId}`} xs={12} item>
//                             <Typography component="h3" variant="h3" className={classes.itemHeader}>
//                                 Customer: {r.custName}
//                             </Typography>
//                             <Typography component="h3" variant="h3" className={classes.itemHeader}>
//                                 Item Name: {r.itemCode} - {r.itemName}
//                             </Typography>
//                             <Typography component="h3" variant="h3" className={classes.itemHeader}>
//                                 Expected Delivery Date: {moment(r.expectedDeliveryDate).format('DD-MM-YYYY')}
//                             </Typography>
//                             <Typography component="h3" variant="h3" className={classes.itemHeader}>
//                                 Status: {r.statusDesc}
//                             </Typography>
//                         </Grid>
//                     </Grid>
//                 </ExpansionPanelSummary>
//                 <ExpansionPanelDetails className={classes.expandedContainer}>
//                     <Grid container>
//                         <Grid id={`expanded-panel-${r.receivalId}`} xs={12} item>
//                             <DrfTableRow label="Item Name:" value={r.itemName} />
//                             <DrfTableRow label="Status:" value={r.status} />
//                             <DrfTableRow label="Expected Date:" value={moment(r.expectedDeliveryDate).format('DD-MM-YYYY')} />
//                             <DrfTableRow label="Expected Qty:" value={r.productAmount} />
//                         </Grid>
//                     </Grid>
//                     <Button color="primary" variant="contained" href={`/received/${r.receivalId}`}>
//                         View DRF
//         </Button>
//                 </ExpansionPanelDetails>
//             </ExpansionPanel>
//             : null
//     )
//     );
//     return (
//         <Grid item xs={12} className={classes.table} key="drf-grid">
//             {items}
//         </Grid>
//     )
// }