import React from 'react';
import { Button } from '@material-ui/core';
import { Auth } from 'aws-amplify';

export async function UserLogout() {
    await Auth.signOut();
}
export default function Logout() {

    return (
        <div>
            <Button
                id={"logout"}
                onClick={UserLogout}
            >
                Logout
                </Button>
        </div>
    );
}