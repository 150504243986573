import React from 'react';
import  ApplicationRouter from './ApplicationRouter';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <ApplicationRouter /> 
    </div>
  );
}

export default App;
