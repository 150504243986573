import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure(config);

const getReceivables = `query getReceivables($limit: Int, $offset: Int){
  getReceivables(limit: $limit, offset: $offset) {
    ReceivableID
    CustID
    CustName
    UserID
    ItemID
    ItemName
    CustItemID
    Quantity
    CreatedTime
    ExpectedDate
    Status
    StatusDesc
    Supplier
    Shipper
    Comment
    ReceivedDate
    ReceivedBy
    UnitCount
    UnitID
    CountedDate
    CountedBy
    ActualQty
    CountingTime
  }
}`;

const getReceivable = `query getReceivable($id: Int){
    getReceivable(id: $id) {
      ReceivableID
      CustName
      ItemName
      Quantity
      ExpectedDate
      Supplier
      Shipper
      Status
      StatusDesc
      ReceivedBy
      ReceivedDate
      ActualQty
      UnitCount
      UnitID
      CountedDate
      CountedBy
      ItemID
      CountingTime
    }
  }`;


export interface ReceivableData {
  custName: string;
  status: number;
  statusDesc: string;
  receivalId: number;
  productAmount: number;
  itemName: string;
  itemCode: string;
  expectedDeliveryDate: Date;
  supplier: string;
  courier: string;
  actualDeliveryDate: Date;
  actualDeliveryAmount: number;
  countingTime: number;
}
export const getSingleDRF = async (id:string) => {
const aReceivable = await API.graphql(graphqlOperation(getReceivable, { id: id }));
  const receivalsData = aReceivable.data;
  const getAReceivable: [] = receivalsData.getReceivable;
  return getAReceivable;
}

const getDRF = async () => {
  const allReceivables = await API.graphql(graphqlOperation(getReceivables, {limit: 20 , offset: 0}));
  const receivalsData = allReceivables.data;
  const getReceivable = receivalsData.getReceivables;
  const tableData: ReceivableData[] = [];
  let tableDataKey: any = {};
  for (tableDataKey of getReceivable) {
    if (tableDataKey.Quantity > 0) {
      const row: ReceivableData = {
        custName: tableDataKey.CustName,
        status: tableDataKey.Status,
        statusDesc: tableDataKey.StatusDesc,
        receivalId:  tableDataKey.ReceivableID,
        productAmount: tableDataKey.Quantity,
        itemName: tableDataKey.ItemName,
        itemCode: tableDataKey.CustItemID,
        expectedDeliveryDate: tableDataKey.ExpectedDate,
        supplier: tableDataKey.Supplier,
        courier: tableDataKey.Shipper,
        actualDeliveryDate: tableDataKey.ReceivedDate,
        actualDeliveryAmount: tableDataKey.ActualQty,
        countingTime: tableDataKey.CountingTime,
      }
      tableData.push(row);
    }
  }
  return {
    tableData,
  };
};

export default getDRF;
