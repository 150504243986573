import React, { useEffect, useState } from 'react';
import
{makeStyles,
  Typography,
  Grid,
}
from '@material-ui/core';
import DrfEditable from './drfEditableTable';
import getDRF, { ReceivableData } from '../helper/tableData';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  title: {
    marginTop: '40px',
    marginLeft: '665px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '100px',
    },
  },
  mobileTitle: {
    marginTop: '100px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '330px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '32px',
    },
  },
}));

function isMobileDevice() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

function DRF() {
  const classes = useStyles();
  const receivableData: ReceivableData[] = [];
  const [receivables, setReceivables] = useState(receivableData);

  const listQuery = async () => {
    const allReceivables = await getDRF();
    const receivalsData = allReceivables.tableData;
    setReceivables(receivalsData);
  };

  useEffect(() => {
    listQuery();
  }, []);

  return (
    <Grid container item xs={12}>
      <div className={classes.root}>
        <Typography className={isMobileDevice() === true ? classes.mobileTitle : classes.title} variant="h1" noWrap id="drf">
          Delivery Receival Form
        </Typography>
      </div>
      <DrfEditable receivables={receivables} />
    </Grid>
  );
}

export default DRF;
