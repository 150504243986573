import React from 'react';
import { createStyles, makeStyles, Theme, Button, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import awsconfig from '../../aws-exports';
import { green } from '@material-ui/core/colors';
import { Delete, Attachment, ExpandMore } from '@material-ui/icons';
import Amplify, { Storage } from 'aws-amplify';
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        onClickButton: {
            background: green[500]
        },
        heading: {
            padding: '30px',
            color: '#ffffff'
        },
        panel : {
            display: 'block',
            background: '#ffffff'
        },
        warningPanel: {
            background:'#c34d65'
        },
        expandIcon: {
            height: 'inherit',
            color: '#ffffff'
        }
    }));

interface Props {
    Image: string;
    ReceivalId: string;
}

const PendingUpload = (props: Props) => {
    const classes = useStyles();
    const [UploadStatusState, setUploadStatus] = React.useState(false)

    function discardImage() {
        localStorage.removeItem(`${process.env.REACT_APP_PREFIX}${props.ReceivalId}`)
    }

    function Upload() {
        setUploadStatus(true)
        let buf = new Buffer(props.Image.replace(/^data:image\/\w+;base64,/, ""), 'base64')
        Storage.put(`${process.env.REACT_APP_PREFIX}${props.ReceivalId}.png`, buf, {
            ContentType: 'image/png',
            ContentEncoding: 'base64',
        })
            .then(result => console.log(result))
            .catch(err => console.log(err));
    }

    return (
        <ExpansionPanel className={classes.warningPanel} key={`expansion-panel-${props.ReceivalId}`} id={`expansion-panel-${props.ReceivalId}`}>
            <ExpansionPanelSummary id={`expansionSummary-${props.ReceivalId}`}>
            <ExpandMore className={classes.expandIcon}/>
                <Typography component="h3" variant="h3" className={classes.heading} >
                    Image Pending Upload
            </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.panel}>
                <img src={props.Image} alt="Preview" style={{ width: '350px' }} />
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={discardImage}
                        startIcon={<Delete />}
                    >
                        Delete
                </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={Upload}
                        className={UploadStatusState ? classes.onClickButton : ''}
                        startIcon={<Attachment />}
                    >
                        Upload
            </Button>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default PendingUpload;