import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure(config);

export const confirmReceivable = `mutation confirmReceivable($receivableID: Int , $itemID: Int, $actualQty: Int){
    confirmReceivable(ReceivableID: $receivableID, ItemID: $itemID, ActualQty: $actualQty) {
        ReceivableID
        ItemID
        ActualQty
    }
}`;

const confirmDRF = async (id: string, qty: string, itemID: string) => {
    await API.graphql(graphqlOperation(confirmReceivable, {
        receivableID: id,
        actualQty: qty,
        itemID: itemID
    }));
}

export default confirmDRF;
