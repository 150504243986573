import React from 'react';
import { makeStyles, Grid, Typography, Button, AppBar, Tabs, Tab, InputLabel, Select, MenuItem, Card } from '@material-ui/core';
import Amplify from 'aws-amplify';
import moment from 'moment';
import config from '../aws-exports';
import { ReceivableData } from '../helper/tableData';
import { TabPanel, tabProps } from '../helper/drfPanel';

Amplify.configure(config);

const useStyles = makeStyles(theme => ({
  buttonDrf: {
    margin: '10px 0',
    width: '100%',
  },
  cardItem: {
    padding: '8px',
  },
  container: {
    left: '8px',
    paddingTop: '1em',
    display: 'grid',
  },
  expandedContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  itemHeader: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  table: {
    marginTop: '50px'
  },
  appBar: {
    display: 'contents',
    position: 'static',
    marginTop: '50px'
  },
  tabPanel: {
    width: '100%',
  },
  tab: {
    color: '#4088c6 !important',
    backgroundColor: '#fafafa',
    marginTop: '50px',
    width: '100%',
    fontWeight: 700
  },
  selectInput: {
    marginTop: '100px',
    display: 'grid',
    width: '100%',
    [theme.breakpoints.down('md')]: {
    marginTop: '70px',
    },
  }
}));

export interface DrfEditableProps {
  receivables: ReceivableData[];
}

const DrfEditable = (props: DrfEditableProps) => {
  const classes = useStyles();
  const { receivables } = props;
  const [value, setValue] = React.useState(0);
  const uniqueCustomer = receivables && receivables.map(item => item.custName)
  const filterCustomer = uniqueCustomer && uniqueCustomer.filter((value, index, self) => self.indexOf(value) === index)
  const [selectedCustomer, setSelectedCustomer] = React.useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCustomerChange = (event, newValue): void => {
    setSelectedCustomer(newValue.props.value);
  };

  function receivableItems(status: number, customer?: string) {

    const filteredReceivables = customer && customer.length > 0 ? 
      receivables.filter(r => customer === r.custName)
      : receivables;
    const displayedReceivables = filteredReceivables.filter(r => status === r.status);

    const items = displayedReceivables.map(r => (
      <Card className={classes.cardItem} key={r.receivalId}>
        <Grid id={`${r.receivalId}`} xs={12} item>
          <Typography component="h3" variant="h3" className={classes.itemHeader}>
            Customer: {r.custName}
          </Typography>
          <Typography component="h3" variant="h3" className={classes.itemHeader}>
            Item Name: {r.itemCode} - {r.itemName}
          </Typography>
          <Typography component="h3" variant="h3" className={classes.itemHeader}>
            Expected Delivery Date: {moment(r.expectedDeliveryDate).format('DD-MM-YYYY')}
          </Typography>
          <Typography component="h3" variant="h3" className={classes.itemHeader}>
            Expected Quantity: {r.productAmount}
          </Typography>
        </Grid>
        <Button color="primary" variant="contained" href={`/received/${r.receivalId}`} className={classes.buttonDrf}>
          View DRF
        </Button>
      </Card>
      )
    );
    return (
      <Grid item xs={12} className={classes.table} key="drf-grid">
        {items}
      </Grid>
    )
  }

  return (
    <>
      <div className={classes.selectInput}>
        <InputLabel id="label">Filter By Customer</InputLabel>
        <Select
          labelId="label"
          id="select"
          onChange={handleCustomerChange}
        >
          {filterCustomer && filterCustomer.map(select => (
            <MenuItem value={select} key={select}>
              {select}
            </MenuItem>
          ))}
          <MenuItem value={''} key={''}>
            Reset
          </MenuItem>
        </Select>
      </div>
      <AppBar className={classes.appBar}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth" className={classes.tabPanel}>
          <Tab label="Awaiting Delivery" {...tabProps(0)} className={classes.tab} />
          <Tab label="Awaiting Count" {...tabProps(1)} className={classes.tab} />
          <Tab label="Stocked" {...tabProps(2)} className={classes.tab} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        {receivableItems(1, selectedCustomer)}
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
        {receivableItems(2, selectedCustomer)}
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabPanel}>
        {receivableItems(3, selectedCustomer)}
      </TabPanel>
    </>
  )
}

export default DrfEditable;
