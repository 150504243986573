
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { constructUrl }from './aws-exports';

interface IPrivateRouteState {
  authenticated: boolean;
  isAuthenticating: boolean;
}

export default class PrivateRoute extends React.Component<RouteProps, IPrivateRouteState> {
  constructor(props: RouteProps) {
    super(props);
    this.state = {
      authenticated: false,
      isAuthenticating: true,
    };
  }

  public async UNSAFE_componentWillMount() {
    setTimeout(async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          const nextState = {
            authenticated: true,
            isAuthenticating: false,
          };
          this.setState(nextState);
        }
      } catch (e) {
        const nextState = {
          authenticated: false,
          isAuthenticating: false,
        };
        this.setState(nextState);
      }
    }, 500);
  }

  public render() {
    const { ...rest } = this.props;
    const { authenticated, isAuthenticating } = this.state;

    if (authenticated) {
        return <Route {...rest} component={this.props.component} />;
    }

    if (isAuthenticating) {
      return null;
    }

    window.location.assign(constructUrl.url);
    return null;
  }
}
