import { Overrides } from '@material-ui/core/styles/overrides';

export const overrides: Overrides = {
  MuiButton: {
    root: {
      fontFamily: 'Helvetica',
      margin: '1em',
      textAlign: 'center',
      color: 'white',
      backgroundColor: '#3072ac',
      borderColor: '#296192',
      backgroundImage: 'linear-gradient(to bottom, #448ccb 0%, #3072ac 100%)',
      backgroundRepeat: 'repeat-x',
    },
    textPrimary : {
      color: 'white',
    },
  },
  MuiAppBar:{
    root: {
      margin: '0px',
      backgroundColor: '#3072ac !important',
      borderColor: '#296192',
      backgroundImage: 'linear-gradient(to bottom, #448ccb 0%, #3072ac 100%)',
      backgroundRepeat: 'repeat-x',
    }
  },
  MuiTabs: {
    fixed: {
      width: '100%',
    },
    scroller: {
      mixBlendMode: 'lighten',
      fontWeight: 'normal',
      color: 'white',
      flex: 'none',
    },
  },
  MuiPaper: {
    root: {
      backgroundColor: 'none',
      margin: '10px'
    },
    elevation2: { 
      boxShadow: 'none',
    }
  },
  MuiTextField: { 
    root: {
      margin: '10px'
    }
  },
  MuiFormControl: {
    root: {
      margin: '10px'
    }
  },
  MuiInput:{
    input:{
      display: 'flex'
    }
  },
  MuiDialogContent: {
    root : {
      backgroundColor: 'white'
    }
  },
  MuiDialogActions : {
    root: {
      backgroundColor: 'white'
    }
  },
  MuiPickersToolbarButton: {
    toolbarBtn: {
      background: 'transparent' 
    }
  },
  MuiPopover: {
    paper: {
      backgroundColor: 'white'
    }
  }
};

