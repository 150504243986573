import { PaletteOptions } from '@material-ui/core/styles/createPalette';

declare type Colour = {
  [color: string]: string;
};

export const colors: Colour = {
  darkGrey: '#231f20',
  lightGrey: '#e8e5e2',
  midGrey: '#333132',
  blue: '#0070DC',
};

export const palette: PaletteOptions = {
  primary: {
    main: colors.blue,
    dark: colors.midGrey,
    light: colors.lightGrey,
  },
  secondary: {
    main: colors.midGrey,
    dark: colors.darkGrey,
  },
  action: {
    hover: colors.blue,
    active: colors.blue,
    selected: colors.blue,
    hoverOpacity: 0.1,
  },
  text: {
    primary: colors.darkGrey,
  },
};
