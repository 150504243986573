import React, { useEffect , useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button} from '@material-ui/core';
import CameraImage from './cameraImage';
import ExistingImages from './existingImages';
import { RouteComponentProps } from 'react-router-dom';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import PendingUpload from './pendingUpload';

const useStyles = makeStyles(theme => ({
    paperRoot: {
        display:'grid',
    },
    gridContainer:{
        display: 'grid'
    },
    wrapper: { 
        [theme.breakpoints.only('sm')]: {
            paddingBottom: '150px',
        },
        [theme.breakpoints.only('md')] : {
            paddingBottom: '500px',
        }
    },
    navigation: {
        width: 'fit-content',
        display: 'inline-flex',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '70px'
        }
    },
    navigationWrapper: {
        display: 'grid',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
        },
        [theme.breakpoints.only('md')]: {
            marginTop: '60px',
        }
    }
}));

interface ViewRouteProps {
    ReceivalId: string
}

const CapturePhoto: React.SFC<RouteComponentProps<ViewRouteProps>> = (
    props: RouteComponentProps<ViewRouteProps>,
) => {
    const classes = useStyles();
    const [offlineImage, setOfflineImage] = useState('');
    const gridSize = isMobileDevice() === true ? 11 : 12;

    function CheckForOfflineImage () { 
        if (`${process.env.REACT_APP_PREFIX}${props.match.params.ReceivalId}` in localStorage ) { 
            setOfflineImage(localStorage.getItem(`${process.env.REACT_APP_PREFIX}${props.match.params.ReceivalId}`) || '')
            return offlineImage;
        } else { 
            setOfflineImage('')
            return offlineImage;
        }
    }

    function isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    useEffect(() => {
        CheckForOfflineImage();
    });

    return (
        <div className={classes.wrapper}>
        <div className={classes.navigationWrapper}>
            <Button
                variant="contained"
                color="secondary"
                className={classes.navigation}
                href={`/received/${props.match.params.ReceivalId}`}
                startIcon={<NavigateBefore />}
            >
                Back
            </Button>
        </div>
            {/* <Paper elevation={0} className={classes.paperRoot}> */}
                <Grid container className={classes.gridContainer} >
                <Grid item xs={gridSize} >
                        <CameraImage ReceivalId={props.match.params.ReceivalId}/>
                    </Grid>
                <Grid item xs={gridSize} >
                        {offlineImage !== '' ?
                        <PendingUpload Image={offlineImage} ReceivalId={props.match.params.ReceivalId}/> 
                        : null
                        }
                    </Grid>
                <Grid item xs={gridSize} >
                        <ExistingImages ReceivalId={props.match.params.ReceivalId}/>
                    </Grid>
                </Grid>
            {/* </Paper> */}
        </div>
    );
}

export default CapturePhoto;