import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const typography: TypographyOptions = {
  button: {
    textTransform: 'capitalize',
  },
  fontSize: 16,
  h1: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '32px',
    lineHeight: '37px',
    color: '#444444',
  },
  h2: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '26px',
    color: '#444444',
  },
  h3: {
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000000',
  },
  h4: {
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#777777',
    backgroundColor: '#0170dc'
  },
  h5: {
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontSize: '19px',
    lineHeight: '1.2em',
    color: '#000000',
  },
  h6: {
    fontFamily: 'Helvetica',
    fontSize: '1.4285714285714284rem',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  body1: {
    fontFamily: 'Helvetica',
    fontSize: '17px',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#000000',
  }
};
