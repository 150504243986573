import React, { useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { createStyles, makeStyles, Theme, Button } from '@material-ui/core';
import { Delete, Attachment}from '@material-ui/icons';
import { Offline , Online } from 'react-detect-offline';
import { UploadStatus } from '../Notification/notification';
import Amplify, { Storage } from 'aws-amplify';
import  awsconfig from '../../aws-exports';
import { green } from '@material-ui/core/colors';
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  buttons: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
    onClickButton: {
      background: green[500]
    },
}));

interface Props {
  ReceivalId: string;
}

function CameraImage (props: Props) {
  const classes = useStyles();
  const [dataUri, setDataUri] = useState('');
  const [UploadStatusState, setUploadStatus] = useState(false)
 
  function handleTakePhotoAnimationDone (dataUri) {
    setDataUri(dataUri);
  }

  function discardImage ()  {
    setDataUri('');
  }

  function OfflineUpload () { 
      localStorage.setItem(`${process.env.REACT_APP_PREFIX}${props.ReceivalId}`, dataUri)
  } 

  function OnlineUpload () { 
    setUploadStatus(true)
    let buf = new Buffer(dataUri.replace(/^data:image\/\w+;base64,/, ""),'base64')
    Storage.put( `${process.env.REACT_APP_PREFIX}${props.ReceivalId}.png`, buf, {
      ContentType: 'image/png',
      ContentEncoding: 'base64',
  })
    .then (result => console.log(result)) 
    .catch(err => console.log(err));
  }
 
  const isFullscreen = false;
  return (
    <div id="camera">
      {
        (dataUri)
          ? 
          <>
          <img src={dataUri} alt="Preview" style={{ width: '350px' }} />
          <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={discardImage}
            startIcon={<Delete />}
          >
            Delete
          </Button>
          <Offline>
            <UploadStatus/>
            <Button
              variant="contained"
              color="secondary"
              onClick={OfflineUpload}
              startIcon={<Attachment />}
            >
              Upload
            </Button>
          </Offline> 
          <Online>
            <Button
              variant="contained"
              color="secondary"
              onClick={OnlineUpload}
              className={UploadStatusState? classes.onClickButton : ''}
              startIcon={<Attachment />}
            >
              Upload
            </Button>
          </Online>
          </div>
          </>
          : <Camera 
                onTakePhotoAnimationDone = {handleTakePhotoAnimationDone}
                isFullscreen={isFullscreen}
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                isImageMirror = {false}
          />
      }
    </div>
  );
}

export default CameraImage;