import * as React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { theme } from './theme';
import makeStyles from '@material-ui/styles/makeStyles';
import  DRF  from './components/drf';
import MobileMenuBar from './components/menuBars/mobilemenuBar';
import DesktopMenuBar from './components/menuBars/desktopMenuBar';
import { Offline } from 'react-detect-offline';
import { NotificationStatus } from './components/Notification/notification';
import PrivateRoute from './privateRoute';
import DrfReceived from './components/drfReceivedTable';
import CapturePhoto from './components/camera/capturePhoto';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export const useStyles = makeStyles({
    app: {
      textAlign: 'center',
      height: '100%',
      width: '100%'
    },
  });
  
  function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

const ApplicationRouter = () => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className={classes.app}>
          <Offline>
            <NotificationStatus/>
          </Offline>
          {isMobileDevice() === true ? <MobileMenuBar /> : <DesktopMenuBar/>}
          <Switch>
            <PrivateRoute exact path="/" component={DRF} />
            <Route path= "/received/:ReceivalId" component={DrfReceived}/>
            <Route path="/capture/:ReceivalId" component={CapturePhoto} />
          </Switch>
        </div>
      </Router>
    </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default ApplicationRouter;
