import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography, Button, Card, CardContent, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Form } from 'react-final-form'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import config from '../aws-exports';
import { RouteComponentProps } from 'react-router-dom';
import { getSingleDRF } from '../helper/tableData';
import updateDRF, { updateReceivables } from '../helper/updateReceivable';
import confirmDRF from '../helper/confirmReceivable';
import DrfTableRow from '../helper/drfTableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { NavigateBefore } from '@material-ui/icons';
import moment from 'moment';
import { green } from '@material-ui/core/colors';
Amplify.configure(config);

const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        marginTop: '70px',
    },
    wrapper: {
        paddingBottom: '200px',
        [theme.breakpoints.only('md')]: {
            paddingBottom: '500px'
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '100px'
        }
    },
    card: {
        marginLeft: '60px',
        marginRight: '60px',
        marginTop: '50px'
    },
    formGrid: {
        margin: '20px',
        [theme.breakpoints.down('md')]: {
            display: 'inline-grid'
        }
    },
    gridCard: {
        display: 'grid',
        [theme.breakpoints.down('md')]: {
            display: 'contents',
        },
    },
    gridEdit: {
        display: 'contents'
    },
    button: {
        display: 'block',
        marginLeft: '30px'
    },
    buttonGroup: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
        }
    },
    onClickButton: {
        display: 'block',
        marginLeft: '30px',
        background: green[500]
    },
    navigation: {
        width: 'fit-content',
        display: 'inline-flex',
        marginLeft: '70px'
    }
}));


interface ViewRouteProps {
    ReceivalId: string
}

const DrfReceived: React.FC<RouteComponentProps<ViewRouteProps>> = (
    props: RouteComponentProps<ViewRouteProps>,
) => {
    const classes = useStyles();
    const [receivable, setReceivable] = React.useState();
    const [actualDatePicker, setActualDatePicker] = React.useState();
    const [countedDatePicker, setCountedDatePicker] = React.useState();
    const [actualDeliveryDate, setActualDeliveryDate] = React.useState();
    const [actualDeliveryAmount, setActualDeliveryAmount] = React.useState();
    const [unitID, setUnitID] = React.useState('');
    const [unitCount, setUnitCount] = React.useState('');
    const [countedDate, setCountedDate] = React.useState('');
    const [itemID, setItemID] = React.useState('');
    const [status, setStatus] = React.useState();
    const [countingTime, setCountingTime] = React.useState(15);
    const [inputValues, setInputValues] = React.useState({
        actualDeliveryDate: actualDeliveryDate,
        actualDeliveryAmount: actualDeliveryAmount,
        unitID: unitID,
        unitCount: unitCount,
        countedDate: countedDate,
        countingTime: countingTime,
    });
    const [amountError, setAmountError] = React.useState(false);
    const [save, setSaveStatus] = React.useState(false);
    const [submit, setSubmitStatus] = React.useState(false);

    const actualAmount = inputValues.actualDeliveryAmount !== null ? inputValues.actualDeliveryAmount : actualDeliveryAmount
    const actualID = inputValues.unitID !== '' ? inputValues.unitID : unitID
    const actualUnitCount = inputValues.unitCount !== '' ? inputValues.unitCount : unitCount

    const handleOnChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const datePickerOnChange = (date) => {
        setActualDatePicker(date);
        setSaveStatus(false);
        setSubmitStatus(false);
    };

    const countDatePickerOnChange = (date) => {
        setCountedDatePicker(date);
        setSaveStatus(false);
        setSubmitStatus(false);
    };

    function validateAmount() {
        if (actualAmount < 1) {
            setAmountError(true)
            return amountError
        } else if (actualAmount >= 1) {
            setAmountError(false)
            return amountError
        }
    }

    async function getStatus() {
        validateAmount()
        if (actualID != null && actualUnitCount != null && actualAmount == null) {
            setStatus(2)
            return status
        } else if (actualID != null && actualUnitCount != null && actualAmount != null) {
            setStatus(3)
            return status;
        }
    }

    async function onSubmit() {
        setSubmitStatus(true);
        await confirmDRF(
            props.match.params.ReceivalId,
            actualAmount,
            itemID
        )
    }

    function resetOnBlur() {
        setSaveStatus(false)
        setSubmitStatus(false)
    }

    async function onSave() {
        setSaveStatus(true)
        await getStatus();
        await updateDRF(
            props.match.params.ReceivalId,
            actualDatePicker ? actualDatePicker : actualDeliveryDate,
            inputValues.actualDeliveryAmount !== null ? inputValues.actualDeliveryAmount : actualDeliveryAmount,
            inputValues.unitID !== '' ? inputValues.unitID : unitID,
            inputValues.unitCount !== '' ? inputValues.unitCount : unitCount,
            countedDatePicker ? countedDatePicker : countedDate,
            status,
            inputValues.countingTime ? inputValues.countingTime : countingTime,
        )

    }

    const listQuery = async () => {
        const aReceivable = await getSingleDRF(props.match.params.ReceivalId);
        setReceivable(aReceivable);
    };

    useEffect(() => {
        // eslint-disable-next-line
        const updatedReceivables = async () => {
            const subscription = await API.graphql(graphqlOperation(updateReceivables)).subscribe();
            subscription.unsubscribe();
        }
        listQuery();
        getStatus();
        // eslint-disable-next-line
    }, []);

    const receivableItems = (receivable ? receivable.map(r => (
        <div className={classes.wrapper} key={`id-${r.receivalId}`}>
            <Grid container className={classes.container} spacing={9}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.navigation}
                    href={'/'}
                    startIcon={<NavigateBefore />}
                >
                    Back
                </Button>
                <Typography component="h3" variant="h3">
                    DRF {r.ReceivableID}
                </Typography>
                <Form
                    onSubmit={onSubmit}
                    validate={values => {
                        const errors = {
                            actualDeliveryAmount
                        }
                        if (values.actualDeliveryAmount < 1) {
                            errors.actualDeliveryAmount = 'Must be greater than 1'
                        }
                        return errors
                    }}
                    subscription={{ submitting: true, pristine: true }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <>
                                <Card id="ReceivedDRF" className={classes.card} >
                                    <CardContent>
                                        <Grid container key={`id-${r.receivalId}`}>
                                            <Grid item xs={6} className={classes.gridCard}>
                                                {setItemID(r.ItemID)}
                                                <DrfTableRow label="Receivable ID:" value={r.ReceivableID} />
                                                <DrfTableRow label="Status:" value={r.StatusDesc} />
                                                <DrfTableRow label="Item Name:" value={r.ItemName} />
                                                <DrfTableRow label="Product Amount:" value={r.Quantity} />
                                                <DrfTableRow label="Expected Delivery Date:" value={moment(r.ExpectedDate).format("DD-MM-YYYY")} />
                                                <DrfTableRow label="Supplier:" value={r.Supplier} />
                                                <DrfTableRow label="Courier:" value={r.Shipper} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid container className={classes.gridEdit} key={`input-id-${r.receivalId}`}>
                                            <Grid item xs={6} className={classes.gridEdit}>
                                                <div className={classes.formGrid}>
                                                    <TextField
                                                        name="receivedBy"
                                                        label="Received By"
                                                        disabled={true}
                                                        defaultValue={r.ReceivedBy}
                                                    />
                                                    <KeyboardDatePicker
                                                        clearable
                                                        label="Received Date"
                                                        onBlur={resetOnBlur}
                                                        value={actualDatePicker || r.ReceivedDate}
                                                        placeholder={actualDatePicker || r.ReceivedDate}
                                                        onChange={date => datePickerOnChange(date)}
                                                        minDate={r.ReceivedDate ? r.ReceivedDate : new Date()}
                                                        format="DD/MM/YYYY"
                                                        {...setActualDeliveryDate(r.ReceivedDate)}
                                                    />
                                                    <FormControl>
                                                        <InputLabel id="select-label">Unit </InputLabel>
                                                        <Select
                                                            name="unitID"
                                                            defaultValue={r.UnitID}
                                                            onBlur={getStatus && resetOnBlur}
                                                            {...setUnitID(r.UnitID)}
                                                            onChange={handleOnChange}
                                                        >
                                                            <MenuItem value={1}>Pallet</MenuItem>
                                                            <MenuItem value={2}>Box</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        name="unitCount"
                                                        label="Unit Count"
                                                        defaultValue={r.UnitCount}
                                                        onBlur={getStatus && resetOnBlur}
                                                        {...setUnitCount(r.UnitCount)}
                                                        onChange={handleOnChange}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridEdit}>
                                                <div className={classes.formGrid}>
                                                    <KeyboardDatePicker
                                                        clearable
                                                        label="Counted Date"
                                                        onBlur={resetOnBlur}
                                                        value={countedDatePicker || r.CountedDate}
                                                        placeholder={countedDatePicker || r.CountedDate}
                                                        onChange={date => countDatePickerOnChange(date)}
                                                        minDate={r.CountedDate ? r.CountedDate : new Date()}
                                                        format="DD/MM/YYYY"
                                                        {...setCountedDate(r.CountedDate)}
                                                    />
                                                    <TextField
                                                        name="countedBy"
                                                        label="Counted by"
                                                        disabled={true}
                                                        defaultValue={r.CountedBy}
                                                    />
                                                    <TextField
                                                        name="actualDeliveryAmount"
                                                        label="Actual Delivery Amount"
                                                        defaultValue={r.ActualQty}
                                                        onBlur={getStatus && resetOnBlur}
                                                        error={amountError}
                                                        helperText={amountError === true ? "Enter a minimum count of 1" : null}
                                                        {...setActualDeliveryAmount(r.ActualQty)}
                                                        onChange={handleOnChange}
                                                    />
                                                    <FormControl>
                                                        <InputLabel id="select-label">Counting time </InputLabel>
                                                        <Select
                                                            name="countingTime"
                                                            defaultValue={r.CountingTime}
                                                            {...setCountingTime(r.CountingTime)}
                                                            onChange={handleOnChange}
                                                        >
                                                            <MenuItem value={15}>15 minutes</MenuItem>
                                                            <MenuItem value={30}>30 minutes</MenuItem>
                                                            <MenuItem value={45}>45 minutes</MenuItem>
                                                            <MenuItem value={60}>60 minutes</MenuItem>
                                                            <MenuItem value={75}>75 minutes</MenuItem>
                                                            <MenuItem value={90}>90 minutes</MenuItem>
                                                            <MenuItem value={120}>120 minutes</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={classes.buttonGroup}>
                                            <Button
                                                id="save"
                                                color="primary"
                                                variant="contained"
                                                className={save ? classes.onClickButton : classes.button}
                                                onClick={onSave}
                                            >
                                                Save
                                        </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.button}
                                                href={`/capture/${r.ReceivableID}`}
                                            >
                                                Take A Photo
                                        </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                className={submit ? classes.onClickButton : classes.button}
                                                onClick={onSubmit}
                                            >
                                                Submit
                                        </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </>
                        </form>
                    )}
                />
            </Grid>
        </div>
    )) : <> No DRF Found</>
    );
    return (
        <Grid item xs={12} key="drf-grid">
            {receivableItems}
        </Grid>
    );
}

export default DrfReceived;