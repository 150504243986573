import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperRoot: {
    backgroundColor: 'white',
    margin: '0px'
  },
  root: {
    display: 'initial',
  },
  addButton: {
    background: '#3f85c3',
    color: '#ffffff',
    borderRadius: '18px',
    top: '-24px',
    left: '180px',
    marginBottom: '-2px',
    width: '100px',
    '&:hover': {
      backgroundColor: '#3f85c396',
      color: '#bec5dad9',
    },
    [theme.breakpoints.up('sm')]: {
      left: '500px',
    },
  },
  installText: {
    marginTop: '18px',
    marginLeft: '26px',
    marginBottom: '-34px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-50px',
    },
  },
  gridItemSpace: {
    marginLeft: '50px',
    display: 'contents',
  },
  gridSpace: {
      display: 'block',
      width: 'min-content'
  },
}));

export default function Banner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paperRoot}>
        <Grid container xs={10} alignItems="center" className={classes.gridSpace}>
          <Grid item xs={10} className={classes.gridItemSpace}>
            <Typography variant="body1" id="banner" className={classes.installText}>
              Add to Home Page
          </Typography>
            <Button
              className={classes.addButton}
              color="secondary"
            >
              Install
        </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}